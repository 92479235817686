// src/axios.js
import axios from 'axios';
import router from "@/router";

// axios.defaults.baseURL = 'http://127.0.0.1:8080';
axios.defaults.baseURL = 'https://work.qianyilab.com/api';
axios.defaults.timeout = 10000;
// 可以添加拦截器等配置

export default axios;

// 请求拦截器
axios.interceptors.request.use(config => {
    // 比如在请求头中添加 token
    const token = localStorage.getItem('token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// 响应拦截器
axios.interceptors.response.use(response => {
    return response;
}, error => {
    // 处理请求失败的情况
    if (error.response) {
        // 处理错误码如 401、500 等
        if (error.response.status === 401) {
            // 例如，跳转到登录页面
            router.push('/login');
        }
        console.error(error.response);
    } else if (error.request) {
        console.error(error.request);
    } else {
        console.error('Error', error.message);
    }
    return Promise.reject(error);
});
