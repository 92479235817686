import Vue from 'vue'
import App from './App.vue'
import router from './router'


//引入ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false
Vue.use(ElementUI);

// 检查 sessionStorage 中是否存在特定标志
if (!sessionStorage.getItem('sessionInitialized')) {
  console.log('项目重启检测：清除登录状态');

  // 第一次加载（重启项目）时清除登录状态
  localStorage.removeItem('authToken'); // 清除 token
  localStorage.removeItem('user');      // 清除用户信息

  // 设置 session 标志，表示当前会话已初始化
  sessionStorage.setItem('sessionInitialized', 'true');
} else {  
  console.log('刷新页面检测：保留登录状态');
}
 
import axios from './api/axios';
Vue.prototype.$axios = axios;


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
