<!-- src/components/Header.vue -->
<template>
  <el-header style="font-size: 40px; background-color: rgb(238, 241, 246); position: sticky; top: 0; z-index: 10;">
    <div class="header-content">
      <span>遇信辅助系统</span>
      <div class="user-info">
        <span>欢迎，{{ user.name==undefined?"":user.name }}</span>
        <el-dropdown trigger="click" @command="handleCommand">
          <div class="avatar-wrapper">
            <img :src="user.avatar" class="user-avatar" />
            <el-icon><caret-bottom /></el-icon>
          </div>
          <el-dropdown-menu slot="dropdown">
            <!-- 上传头像 -->
            <el-dropdown-item command="updateAvatar">
              更新头像
            </el-dropdown-item>
            <el-dropdown-item command="logout">
              退出登录
            </el-dropdown-item>
            <el-dropdown-item command="personal">
              个人中心
            </el-dropdown-item>
            <el-dropdown-item command="attention">
              注意事项
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <el-button @click.native="logout">登出</el-button> -->
        <!-- 文件上传弹窗 -->

      </div>
    </div>

    <el-dialog title="上传头像" :visible.sync="uploadDialogVisible" width="30%" @close="resetUpload" append-to-body>
      <el-form>
        <el-form-item>
          <input type="file" ref="fileInput" style="display: none;" @change="handleFileChange" />
          <el-button type="primary" @click="triggerFileSelect">
            选择文件
          </el-button>
          <span v-if="selectedFileName">{{ selectedFileName }}</span>
        </el-form-item>
        <!-- 缩略图预览 -->
        <el-form-item v-if="previewImage">
          <img :src="previewImage" alt="头像预览" class="avatar-preview" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="uploadDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="uploadAvatar">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="个人中心" :visible.sync="personalDialogVisible" append-to-body width="50%">
      <el-form :model="userData" ref="userData" label-width="120px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="userData.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input v-model="userData.phone" placeholder="请输入电话"></el-input>
        </el-form-item>
        <el-form-item label="生日" prop="birthday">
          <el-date-picker v-model="userData.birthday" type="date" placeholder="选择日期"
            value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-input v-model="userData.address" placeholder="请输入地址"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="personalDialogVisible = false">取消</el-button>
        <el-button type="primary" @click.native="submitPersonalForm">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="注意事项" :visible.sync="attentionDialogVisble" append-to-body width="50%">
      扫码关注应用，获取邮信消息推送
      <img :src=this.codeUrl alt="扫码关注" class="avatar-preview">
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="attentionDialogVisble = false">取消</el-button>
      </div>
    </el-dialog>
  </el-header>

</template>

<script>
import { updateAvatar, getUserDetail, updateUser } from '@/api/user/user';
import { code } from '@/api/wxpush/wxpush';

export default {
  name: 'PageHeader',
  data() {
    return {
      // 假设用户信息存储在localStorage中
      user: JSON.parse(localStorage.getItem('user')) || {},
      uploadDialogVisible: false, // 控制弹窗显示
      personalDialogVisible: false,
      attentionDialogVisble: false,
      codeUrl: "https://wxpusher.zjiecode.com/api/qrcode/FpvlFxcxYlbfPbrE8j6XmA1cg3Oey5SjK1VG4l8cHaGU89zbTwhL5FZ0NwbXgny7.jpg",
      userData: {},
      selectedFile: null, // 保存选中的文件
      selectedFileName: "", // 保存选中文件名
      previewImage: null, // 缩略图预览地址
    };
  },
  mounted() {
    //登录卡bug后使用
    // localStorage.removeItem('authToken');
    // localStorage.removeItem('token');
    // localStorage.removeItem('user');
    
  },
  created() {
    this.getUser();
  },
  methods: {
    //登出按钮
    logout() {
      const token = localStorage.getItem('token');
      const user = JSON.parse(localStorage.getItem('user'));
      console.log("token", token)
      console.log("user", user)

      localStorage.removeItem('authToken');
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      console.log("点击登出", localStorage.getItem('authToken'))
      console.log("登录状态", !!token)
      // 通知父组件用户已登出
      this.$emit('logout');
      // this.$router.push('/login');
    },
    getUser() {
      getUserDetail(JSON.parse(localStorage.getItem('user')).id).then(res => {
        this.user = res.data.data;
        this.userData = { ...res.data.data }; // 或 Object.assign({}, res.data.data)
        localStorage.setItem('user', JSON.stringify(this.user));
      });
    },
    // 打开文件上传
    openUploadDialog() {
      this.uploadDialogVisible = true;
    },
    //打开个人中心
    openPersonalDialog() {
      this.personalDialogVisible = true;
    },
    //个人中心信息提交
    submitPersonalForm() {
      this.$refs.userData.validate((valid) => {
        if (valid) {
          if (this.userData.id != null) {
            updateUser(this.userData).then(() => {
              this.getUser();
              this.personalDialogVisible = false;
            }).catch((error) => {
              console.dir(error);
            })
          }
        }
      })
    },
    //注意事项
    openAttentionDialog() {
      this.createTempCode();
      this.attentionDialogVisble = true;
    },
    // 触发文件选择
    triggerFileSelect() {
      this.$refs.fileInput.click();
    },
    // 处理文件选择
    handleFileChange(event) {
      const file = event.target.files[0];
      if (!file) {
        this.$message.error("请选择一个文件！");
        return;
      }
      if (!file.type.startsWith('image/')) {
        this.$message.error("请选择图片文件！");
        return;
      }
      this.selectedFile = file;
      this.selectedFileName = file.name;
      this.previewImage = URL.createObjectURL(file); // 创建缩略图预览地址
    },
    // 上传头像
    async uploadAvatar() {
      if (!this.selectedFile) {
        this.$message.error("请先选择一个文件！");
        return;
      }

      const formData = new FormData();
      formData.append("file", this.selectedFile);
      console.log("userId", this.user.id);
      formData.append("userId", this.user.id); // 替换为实际用户ID

      try {
        const response = await updateAvatar(formData);
        if (response.data.code === 200) {
          this.$message.success("头像上传成功！");
          this.getUser();
          this.uploadDialogVisible = false; // 关闭弹窗
          this.resetUpload();//重置状态
        } else {
          this.$message.error(response.data.message || "上传失败！");
        }
      } catch (error) {
        this.$message.error("上传失败，请重试！");
        console.error(error);
      }
    },
    // 重置上传状态
    resetUpload() {
      this.selectedFile = null;
      this.selectedFileName = "";
      this.previewImage = null;
    },
    //个人信息下拉框
    handleCommand(command) {
      this.getUser();
      switch (command) {
        case 'logout': this.logout(); break;
        case 'updateAvatar': this.openUploadDialog(); break;
        case 'personal': this.openPersonalDialog(); break;
        case 'attention': this.openAttentionDialog(); break;
        default: break;
      }
    },
    createTempCode() {
      code(this.user.id).then(res => {
        this.codeUrl = res.data.data.data.url;
      })
    },
  },

};
</script>

<style scoped>
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.avatar-wrapper {
  margin-top: 5px;
  position: relative;
}

.avatar-preview {
  max-width: 100%;
  /* 图片宽度最多占父容器100% */
  max-height: 150px;
  /* 根据需要设置最大高度 */
  object-fit: contain;
  /* 确保图片按比例缩放 */
  display: block;
  /* 避免图片默认的 inline 间隙问题 */
  margin: 0 auto;
  /* 居中对齐 */
}

.user-avatar {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 10px;
}

i {
  cursor: pointer;
  position: absolute;
  right: -20px;
  top: 25px;
  font-size: 12px;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-info span {
  margin-right: 10px;
}

.el-header {
  font-size: 40px;
  background-color: rgb(238, 241, 246);
  position: sticky;
  top: 0;
  z-index: 10;
}

.el-dropdown {
  vertical-align: top;
}

.el-dropdown+.el-dropdown {
  margin-left: 15px;
}

.el-icon-arrow-down {
  font-size: 12px;
}
</style>