<template>
  <html>

  <body>
    <div class="container">
      <div class="top"></div>
      <div class="bottom"></div>
      <div class="center" id="center-content">
        <h2>Please Sign In</h2>
        <el-input type="email" v-model="email" placeholder="Email" aria-required="true" />
        <el-input type="password" show-password v-model="password" placeholder="Password" aria-required="true"
          @keydown.enter.native="onSubmit" />
        <el-row>
          <el-button @click.native="onSubmit">登 入</el-button>
          <el-button @click.native="onRejister">注 册</el-button>
        </el-row>

      </div>
    </div>
  </body>

  </html>
</template>

<script>
import { login, register } from "@/api/user/user";

export default {
  name: 'LoginForm',
  data() {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    onRejister() {
      if (this.email === '' || this.password === '') {
        this.$message.error("邮箱、密码不能为空")
        return;
      }
      const query = {
        email: this.email,
        password: this.password,
      }
      register(query).then(res => {
        if (res.data.code === 200) {
          login(query).then(res => {
            console.dir(res)
            if (res.data.code == 200) {
              // 登录成功后发出事件，通知父组件
              // 假设 res.data 包含 token 和用户信息
              const { token, user } = res.data.data;

              // 将 token 和用户信息存入 localStorage
              localStorage.setItem('token', token);
              localStorage.setItem('user', JSON.stringify(user));

              console.dir(token)
              console.dir(user)
            }
          })
          this.$message.success("注册成功,直接登入");
          this.$emit('login-success');
        } else if (res.data.data === 501) {
          this.$message.error(res.data.msg);
        }
      }).catch(error => {
        console.error(error);
      })
    },
    onSubmit() {
      if (this.email === '' || this.password === '') {
        this.$message.error("邮箱、密码不能为空")
        return;
      }
      const query = {
        email: this.email,
        password: this.password,
      }
      login(query).then(res => {
        console.dir(res)
        if (res.data.code == 200) {
          // 登录成功后发出事件，通知父组件
          // 假设 res.data 包含 token 和用户信息
          const { token, user } = res.data.data;

          // 将 token 和用户信息存入 localStorage
          localStorage.setItem('token', token);
          localStorage.setItem('user', JSON.stringify(user));

          console.dir(token)
          console.dir(user)
          this.$emit('login-success');
        } else if (res.data.code == 500) {
          this.$message.error("请检查邮箱、密码是否正确或是否注册")
        }
      }).catch(error => {
        console.error(error);
      })
    }

  }
};
</script>
<style>
@import url('https://fonts.googleapis.com/css?family=Raleway:400,700');

*,
*:before,
*:after {
  box-sizing: border-box
}

body {
  min-height: 100vh;
}

.container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &:hover,
  &:active {

    .top,
    .bottom {

      &:before,
      &:after {
        margin-left: 200px;
        transform-origin: -200px 50%;
        transition-delay: 0s;
      }
    }

    .center {
      opacity: 1;
      transition-delay: 0.2s;
    }
  }
}

.top,
.bottom {

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 200vmax;
    height: 200vmax;
    top: 50%;
    left: 50%;
    margin-top: -100vmax;
    transform-origin: 0 50%;
    transition: all 0.5s cubic-bezier(0.445, 0.05, 0, 1);
    z-index: 10;
    opacity: 0.65;
    transition-delay: 0.2s;
  }
}

.top {
  &:before {
    transform: rotate(45deg);
    background: #e46569;
  }

  &:after {
    transform: rotate(135deg);
    background: #ecaf81;
  }
}

.bottom {
  &:before {
    transform: rotate(-45deg);
    background: #60b8d4;
  }

  &:after {
    transform: rotate(-135deg);
    background: #3745b5;
  }
}

.center {
  position: absolute;
  width: 400px;
  height: 400px;
  top: 50%;
  left: 50%;
  margin-left: -200px;
  margin-top: -200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.445, 0.05, 0, 1);
  transition-delay: 0s;
  color: #333;

  input {
    width: 100%;
    padding: 15px;
    margin: 5px;
    border-radius: 1px;
    border: 1px solid #ccc;
    font-family: inherit;
  }
}
</style>