import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'login',
        component: ()=>import('../views/login/login.vue'),
    },
    {
        path: '/order',
        name: 'order',
        component: () => import('../views/tlias/OrderView.vue')
    },
    {
        path: '/user',
        name: 'user',
        component: () => import('../views/tlias/UserView.vue')
    }
]

const router = new VueRouter({
    routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('authToken');

    // 如果是去 /emp 页面，并且用户没有登录，重定向到登录页面
    if (to.path === '/order' && !token &&!JSON.parse(localStorage.getItem('user'))) {
        next('/login');
    } else if (to.path === '/login' && token) {
        // 如果用户已经登录，且想访问登录页面，直接跳转到 /emp
        next('/order');
    } else {
        next(); // 其他情况下正常跳转
    }
});

export default router
