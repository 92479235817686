<template>
  <div>
    <!-- 初始显示 login 组件 -->
    <login-view v-if="!isLoggedIn" @login-success="onLoginSuccess"></login-view>
     
    <!-- 登录成功后显示 router-view -->
    <el-header><page-header v-if="isLoggedIn" @logout="handleLogout"></page-header></el-header>
    <router-view v-if="isLoggedIn" @logout="handleLogout"></router-view>
    
  </div>
</template>

<script>
import LoginView from "@/views/login/login.vue";
import PageHeader from "./views/header/PageHeader.vue";

export default {
  components: {
    LoginView,
    PageHeader,
  },
  data() {
    return {
      isLoggedIn: false // 初始状态为未登录
    }
  },
  created() {
    // 初始化时从 localStorage 检查登录状态
    console.log("authToken",localStorage.getItem('authToken'))
    this.isLoggedIn = !!localStorage.getItem('authToken');
    console.log("登录状态",this.isLoggedIn)
  },
  methods: {
    // 登录成功后的回调
    onLoginSuccess() {
      const targetRoute = '/order';

      // 设置登录状态
      this.isLoggedIn = true;
      // 存储登录 token 等信息
      localStorage.setItem('authToken', 'your-auth-token');

      // 跳转到目标页面
      console.log("准备跳转")
      this.$router.push(targetRoute);
    },
    handleLogout() {
      // 登出时更新登录状态并跳转回登录页
      this.isLoggedIn = false;
      console.log("准备登出")
      this.$router.push('/login');
    }
  }

}
//页面缩放报错
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}
</script>

<style>
h1 {
  color: red;
}
</style>
