import axios from "axios";

//带参数的二维码
export function code(userId){
    return axios({
        method: 'POST',
        url: "/wxpusher/code",
        params:{
            userId: userId
        }
    })
}