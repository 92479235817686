import axios from "axios";

//用户模糊列表
export function list(query) {
    return axios({
        method: 'GET',
        url: "/admin/user/list",
        params: query
    })
}
//用户详细
export function getUserDetail(id) {
    return axios({
        method: 'GET',
        url: "/admin/user/detail",
        params: {
            id: id,
        }
    })
}

//登入
export function login(query) {
    return axios({
        method: "POST",
        url: "/admin/user/login",
        params: query
    })
}

//注册
export function register(query) {
    return axios({
        method: "POST",
        url: "/admin/user/add",
        data: query,
        headers: {
            "Content-Type": "application/json"
        }
    })
}

//更新用户
export function updateUser(query){
    return axios({
        method: 'PUT',
        url: "/admin/user/userInfo",
        data: query
    })
}

//上传头像
export function updateAvatar(query) {
    return axios({
        method: "POST",
        url: "/admin/user/uploadAvatar",
        data: query
    })
}